import fetch from "node-fetch"
require('dotenv').config()

const API = class {
  constructor(data, method, url, contentType=null) {
    this.data = data
    this.form = new FormData()
    this.baseURL = process.env.REACT_APP_API_URL
    this.headers = {}
    this.url = url
    this.method = method
    this.contentType = contentType
  }

  addHeaderAuthorization() {
    this.headers['Authorization'] = 'Bearer ' + window.localStorage.getItem("auth-token")
  }

  addHeaderJson() {
    this.headers['Content-Type'] = 'application/json'
  }

  customConfig() {
    return {}
  }

  customHeader() {
    return {}
  }

  toArray(field) {
    let data = this.data
    this.form.delete(field)
    for (let i = 0; i < data[field].length; i++) {
      this.form.append(field + "[]", data[field][i])
    }
  }

  async send() {
    const fetchs = {
      method: this.method,
      headers: this.headers
    }

    if (this.method !== 'GET') {
      if (this.contentType !== 'formData') {
        fetchs.body = JSON.stringify(this.data)
      }else{
        fetchs.body = this.data
      }
    }

    const response = await fetch(this.baseURL + this.url, fetchs)

    let data = await response.text()
    data = JSON.parse(data)
    if (response.status === 500) {
      throw data
    }
    
    return data
  }

  async sendDownload(fileName = 'transaksi-pencairan.xlsx') {
    const fetchs = {
      method: this.method,
      headers: this.headers
    }

    if (this.method !== 'GET') {
      if (this.contentType !== 'formData') {
        fetchs.body = JSON.stringify(this.data)
      }else{
        fetchs.body = this.data
      }
    }

    const res = await fetch(this.baseURL + this.url, fetchs).then(function(resp) {
      return resp.blob();
    }).then(function(blob) {
      if(blob){
        var csvURL = window.URL.createObjectURL(blob);
        let tempLink = document.createElement('a');
        tempLink.href = csvURL;
        const currentdate = new Date(); 
        const datetime = "-"+currentdate.getDate() + "-"
                + (currentdate.getMonth()+1) + "-" 
                + currentdate.getFullYear() + "-"  
                + currentdate.getHours() + "-"  
                + currentdate.getMinutes() + "-" 
                + currentdate.getSeconds();
        tempLink.setAttribute('download', fileName + datetime);
        tempLink.click();
      }
    });
  }
}

export const authenticated = () => {
  return window.localStorage.getItem("logged_in")
}

export default API
