export const extractMessages = (message) => {
  const varType = typeof message
  if (varType === 'object') {
    const messages = []
    for (const key in message) {
      if (message.hasOwnProperty(key)) {
        const element = message[key]
        messages.push(element)
      }
    }

    return messages
  }

  if (varType === 'string') {
    return [message]
  }

  return []
}

export const saveLoggedInInfo = (data) => {
  const {access_token, id, name, email, phone, photo} = data

  if (access_token) {
    localStorage.setItem('auth-token', access_token)
    // localStorage.setItem('auth-token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiZDU2NDUyZTllZjNlZTBhZWU0ZmEyZmU5MzI1M2RmY2EyZDc4YWQxYWRhNjJiZTljNGZjNjEzZTAyZDU3NDkxMGUxMGNhYzVmNmZmNGUwZjciLCJpYXQiOjE2MDA3NjgwNTEsIm5iZiI6MTYwMDc2ODA1MSwiZXhwIjoxNjMyMzA0MDUxLCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.6bvWpPXWcZNj41ItfPgNJUXVbIOrdEp6adRvD1Dzr5JToQ6bXscEaklhorF1alkTdpOr3f8CQWW0WngJxQjDfFebbXIt84mgcgiJtHM7axAX7C-_dY8UJoezEnKcqBx2oULHPuHl6TbxaMFNd9oxVKC4Qhe2p0BMELGJdq1bZ2nX0TmM9B8Enmkm5-ZJryj6glqowi1U_F70sQpNkd3htiUAo7pAnJTghmnkLz9e_8Pu-s1Wi5bF9NjkTyqS8kseaPPwqWmTHJ73VgbbTIphU8om-Bk7xMvbIu3Z17ImkJz7oymsFEPhh9THNNtqr6zFqHR7jK8XtHMb5By6W-XgOHXqO9mCu1LGa-nbGOZ0vsXgNa_1M370zW-6d9eHnunr81do7niNuNvfduFyAN14dsaxMGeiQmbfZIwsDvYsTlNzjQUUlvslGUDL9AxIRJ0CysLwaTxqNz-310V2FGc3gtWvDoAy54isqMSNIFmM-vQenKRTL5R71j98ZluOy8v8v98M5FYIVlDlfTiID0cLtZY1MLdooF5JSxCdY-3le0Qqj3OeA5PkyyQTUY0K0uTr2hSIob-I7R-LQAcmt_5BimGMAPZg7OU7_c7gUDlYicE3I_dyP3Wg8Tac6rgube3TjkBP4IgNl2jRZI24KaUDSt4AGgkgIKs7vpqxeFV-FiQ')
  }

  localStorage.setItem('user', JSON.stringify({
    id, name, email, phone, photo
  }))

  return {id, name, email, phone, photo}
} 