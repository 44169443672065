import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/auth/loginsnjdnskdnsjdnsjdnsj`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

require('dotenv').config()

export async function login(email, password) {
  const data = await axios.post(LOGIN_URL, { email, password })
  console.log({data})
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
