/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
        ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
        : "" ;
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>

          <li
              className={`menu-item ${getMenuItemActive("/admin/campaign-report/list", false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admin/campaign-report/list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Laporan Campaign</span>
            </NavLink>
          </li>

          {/*end::1 Level*/}

          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/error", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Terminal.svg")}
              />
            </span>
              <span className="menu-text">Pencairan</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Pencairan</span>
                </span>
                </li>

                {/*begin::2 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/admin/withdrawal/list")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/withdrawal/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Approval Pencairan</span>
                  </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/admin/transfer-fund")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/transfer-fund">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Transfer Dana</span>
                  </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/admin/disbursement-history")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/disbursement-history">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Riwayat Pencairan</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

              </ul>
            </div>
          </li>

          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/error", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Terminal.svg")}
              />
            </span>
              <span className="menu-text">Campaigner</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Campaigner</span>
                </span>
                </li>

                {/*begin::2 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/admin/campaigner/candidate")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/campaigner/candidate">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Calon Campaigner</span>
                  </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/admin/campaigner")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/campaigner">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Campaigner</span>
                  </NavLink>
                </li>

              </ul>
            </div>
          </li>

          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/error", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Terminal.svg")}
              />
            </span>
              <span className="menu-text">Campaign</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Campaign</span>
                </span>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/admin/campaign/list")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/campaign/list">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Campaign</span>
                  </NavLink>
                </li>
                
                <li
                    className={`menu-item ${getMenuItemActive("/admin/campaign-section")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/campaign-section">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Section</span>
                  </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/admin/campaign-curation")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/campaign-curation">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Kurasi</span>
                  </NavLink>
                </li>

              </ul>
            </div>
          </li>
          
          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/error", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Terminal.svg")}
              />
            </span>
              <span className="menu-text">Content</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Content</span>
                </span>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/admin/faq/topics")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/faq/topics">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">FAQ</span>
                  </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/admin/slider")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/slider">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Slider</span>
                  </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/admin/success-story")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/success-story">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Success Story</span>
                  </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/admin/update-news")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/update-news">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Update</span>
                  </NavLink>
                </li>

              </ul>
            </div>
          </li>

          <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/error", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Terminal.svg")}
              />
            </span>
              <span className="menu-text">Keuangan</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Transaksi</span>
                </span>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/admin/finance/transaction")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/finance/transaction">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Transaksi</span>
                  </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/admin/finance/import/disbursement")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/finance/import/disbursement">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Import Pencairan</span>
                  </NavLink>
                </li>

              </ul>
            </div>
          </li>

          <li
              className={`menu-item menu-item-submenu mt-4 ${getMenuItemActive(
                  "/error", true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Terminal.svg")}
              />
            </span>
              <span className="menu-text">TBT</span>
              <i className="menu-arrow"/>
            </NavLink>
            <div className="menu-submenu ">
              <i className="menu-arrow"/>
              <ul className="menu-subnav">
                <li
                    className={`menu-item ${getMenuItemActive("/admin/tbt/ajuan-tetangga")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/tbt/ajuan-tetangga">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Ajuan Tetangga</span>
                  </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/admin/tbt/donasi-operasional")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/tbt/donasi-operasional">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Donasi Operasional</span>
                  </NavLink>
                </li>

                <li
                    className={`menu-item ${getMenuItemActive("/admin/tbt/donasi-beras")}`}
                    aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/admin/tbt/donasi-beras">
                    <i className="menu-bullet menu-bullet-dot">
                      <span/>
                    </i>
                    <span className="menu-text">Donasi Beras</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          
        </ul>
      </>
  );
}
