import React, {createContext, useContext, useState, useEffect} from "react";
import { extractMessages, saveLoggedInInfo } from "../../helpers/authHelpers";
import API from "../../services/API";

const AuthsUIContext = createContext();

export function useAuthsUIContext() {
  return useContext(AuthsUIContext);
}

export const AuthsUIConsumer = AuthsUIContext.Consumer;

export function AuthUIProvider({children}) {
  const [state, setState] = useState({
    isAuthenticated: true,
    errorMessages: [],
    isLoading: false,
    authUser: {}
  })

  useEffect(() => {
    let user = localStorage.getItem('user')

    if (user) {
      setState({
        ...state,
        authUser: JSON.parse(user),
        isAuthenticated: true
      })
    }else{
      setState({
        ...state,
        isAuthenticated: false
      })
    }
    
  }, []);

  const initAuthUser = async() => {
    try {
      const api = new API({}, 'GET', 'profile');
      api.addHeaderJson()
      api.addHeaderAuthorization()
      const data = await api.send()

      const authUser = saveLoggedInInfo(data.data)
      setState({
        ...state,
        authUser
      })
    } catch (error) {
      console.log(error)
    }
  }

  const login = async (values) => {
    setState({
      ...state,
      isLoading: true
    })

    const {email, password} = values
    const stating = {
      ...state,
      isLoading: false,
      errorMessages: []
    }

    try {
      const api = new API({
        username: email,
        password
      }, 'POST', 'auth/login');
      api.addHeaderJson()
      const data = await api.send()

      const authUser = saveLoggedInInfo(data.data)
      stating.authUser = authUser
      stating.isAuthenticated = true
    } catch (error) {
      stating.errorMessages = extractMessages(error.message)
    }

    setState(stating)
  }

  const logout = () => {
    localStorage.clear();

    setState({
      ...state,
      isAuthenticated: false
    })
  }

  const value = {
    login,
    state,
    initAuthUser,
    logout
  }

  return <AuthsUIContext.Provider value={value}>{children}</AuthsUIContext.Provider>;
}