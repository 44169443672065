import React from 'react';

export const FormMessages = (props) => {
  const {type, messages} = props
  return (
    <>
      {messages.length > 0 && (
        <div className={`mb-10 alert alert-custom alert-${type} alert-dismissible`}>
          <div className="alert-text ">
            <ul>
              {messages.map((item, key) => {
                return (
                  <li key={key}> {item} </li>
                )
              })}
            </ul>
          </div>
        </div>
      )}
    </>
  )
}