import React, {useEffect, useMemo} from "react";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import { useAuthsUIContext } from "../AuthContext";

export function Logout() {
  const authUIContext = useAuthsUIContext();
  const authUIProps = useMemo(() => {
    return {
      state: authUIContext.state,
      logout: authUIContext.logout
    };
  }, [authUIContext]);

  useEffect(() => {
    authUIProps.logout()
  }, [authUIProps])

  const {isAuthenticated} = authUIProps.state

  return isAuthenticated ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
}
